(function() {
    'use strict';

    angular.module('ionicHelp', [
        'ionic',
        'ionicAlert',
        'ionicArticle',
        'ionicData',
        'ionicLogin',
        'ionicRequest',
        'ionicSection',
        'ionicVideo',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicHelp').service(
        'HelpPageService',
        HelpPageService
    );

    HelpPageService.$inject = [
        'DefaultVariableService',
        'ListManagementService'
    ];

    function HelpPageService(
        DefaultVariableService,
        ListManagementService
    ) {
        var HelpPageService = this;

        var HELP_TAG = 'help-page';

        HelpPageService.getArticles = getArticles;
        function getArticles() {
            var options = {};

            options.url = '/articles/get/';
            options.url += '?join=INNER';
            options.url += '&Tags.name=' + HELP_TAG;
            options.url += '&order=title';

            return ArticleListManager.getMore(options);
        }

        HelpPageService.getArticleListManager = getArticleListManager;
        function getArticleListManager() {
            return ArticleListManager;
        }

        HelpPageService.getVideo = getVideo;
        function getVideo() {
            var options = {};

            options.url = '/videos/get/';
            options.url += '?join=INNER';
            options.url += '&Tags.name=' + HELP_TAG;

            return VideoListManager.getPage(options).then(
                function(data) {
                    if (data) {
                        var videos = DefaultVariableService.getArray(
                            data.videos
                        );

                        if (videos.length !== 0) {
                            return videos[0];
                        }
                    }

                    return false;
                }
            );
        }

        HelpPageService.getVideoListManager = getVideoListManager;
        function getVideoListManager() {
            return VideoListManager;
        }
        
        HelpPageService.reset = reset;
        function reset() {
            ArticleListManager.reset();
            VideoListManager.reset();
        }

        var ArticleListManager = ListManagementService.getListManager(
            HelpPageService.getArticles
        );

        var VideoListManager = ListManagementService.getListManager(
            HelpPageService.getVideo
        );
        
        HelpPageService.reset();

        return HelpPageService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicHelp').directive('ionicHelpPage', ionicHelpPage);

    function ionicHelpPage() {
        return {
            controller:   'IonicHelpPageController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {},
            template:'<div class="list"><div data-ng-repeat="section in ctrl.sections"><div class="item item-divider row bar bar-stable buttons" data-ng-click="ctrl.selectSection(section)"><h1 class="title">{{ section.title }}</h1><button class="button button-icon button-clear text-left">{{ ctrl.section.id === section.id ? \'HIDE\' : \'SHOW\' }} <i class="icon" data-ng-class="ctrl.section.id === 0 ? \'ion-arrow-expand\' : \'ion-arrow-shrink\'"></i></button></div><div data-ng-if="section.id === ctrl.section.id"><ionic-video data-ng-if="section.video.id !== 0" show-info="false" show-playlist="false" show-recommendations="false" show-social="false" video="section.video"></ionic-video><ionic-article data-ng-if="section.article.id !== 0" article="section.article" show-social="false"></ionic-article></div></div></div>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicHelp').controller(
        'IonicHelpPageController',
        IonicHelpPageController
    );

    IonicHelpPageController.$inject = [
        'DefaultVariableService',
        'LoginService',
        'IonicHelpPageService'
    ];

    function IonicHelpPageController(
        DefaultVariableService,
        LoginService,
        IonicHelpPageService
    ) {
        var IonicHelpPageController = this;

        IonicHelpPageController.clear = clear;
        function clear() {
            IonicHelpPageController.reset();
            IonicHelpPageService.reset();
        }

        IonicHelpPageController.forceRefresh = forceRefresh;
        function forceRefresh() {
            IonicHelpPageController.clear();
            IonicHelpPageController.init();
        }

        IonicHelpPageController.loadSections = loadSections;
        function loadSections() {
            IonicHelpPageController.isLoadingSections = true;

            return IonicHelpPageService.getSections().then(
                function(sections) {
                    IonicHelpPageController.sections = sections;

                    return sections;
                }
            ).finally(
                function() {
                    IonicHelpPageController.isLoadingSections = false;
                }
            );
        }

        IonicHelpPageController.selectSection = selectSection;
        function selectSection(section) {
            if (IonicHelpPageController.section.id === section.id) {
                IonicHelpPageController.resetSection();
            } else {
                IonicHelpPageController.section = section;
            }
        }

        IonicHelpPageController.reset = reset;
        function reset() {
            IonicHelpPageController.isLoadingSections = true;

            IonicHelpPageController.resetSection();

            IonicHelpPageController.sections = [];
        }

        IonicHelpPageController.resetSection = resetSection;
        function resetSection() {
            IonicHelpPageController.section = DefaultVariableService.getObject();
        }

        IonicHelpPageController.init = init;
        function init() {
            IonicHelpPageController.reset();
            IonicHelpPageController.loadSections();
        }

        LoginService.register(IonicHelpPageController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicHelp').service(
        'IonicHelpPageService',
        IonicHelpPageService
    );

    IonicHelpPageService.$inject = [
        'DefaultVariableService',
        'RequestManagementService',
        'SectionListService'
    ];

    function IonicHelpPageService(
        DefaultVariableService,
        RequestManagementService,
        SectionListService
    ) {
        var IonicHelpPageService = this;

        IonicHelpPageService.getSections = getSections;
        function getSections() {
            var options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setParams(
                options,
                [
                    'Articles',
                    'Videos'
                ]
            );

            options = RequestManagementService.setLimit(options, 100);

            return SectionListService.getMore(options).then(
                function(data) {
                    if (data) {
                        var sections = DefaultVariableService.getArray(
                            data.sections
                        );

                        var sectionsLength = sections.length;
                        for (var i = 0; i < sectionsLength; i++) {
                            sections[i].article = DefaultVariableService.getObject(
                                sections[i].article
                            );

                            sections[i].video = DefaultVariableService.getObject(
                                sections[i].video
                            );
                        }

                        return sections;
                    }
                }
            );
        }
        
        IonicHelpPageService.reset = reset;
        function reset() {
            SectionListService.reset();
        }

        return IonicHelpPageService;
    }
})();